import api from "./api";

const REQUEST_TIMEOUT = 30000;

const asParams = (data) => {
  if (!data) return "";

  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");
};

export const blobRequest = (resource, key, format, options) =>
  api.get(`${resource}/${key}${format}?${asParams(options)}`, {
    responseType: "blob",
    timeout: REQUEST_TIMEOUT,
  });

export const saveAttachment = (response, fullName, type) => {
  const url = window.URL.createObjectURL(new Blob([response.data], { type }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fullName);
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const savePdfAttachment = (response, fileName = "file") => {
  saveAttachment(response, `${fileName}.pdf`, "application/pdf");
};

export const saveXlsxAttachment = (response, fileName = "file") => {
  saveAttachment(
    response,
    `${fileName}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
};

export const downloadInvoice = (id, fileName, options = {}) => {
  blobRequest("invoices", id, ".pdf", options).then((response) =>
    savePdfAttachment(response, fileName)
  );
};

export const downloadPurchaseOrder = (id, fileName, setLoading, template = null) => {
  const requestParams = template ? { template } : null;

  blobRequest("purchase_orders", id, `.pdf`, requestParams)
    .then((response) => savePdfAttachment(response, fileName))
    .finally(() => setLoading && setLoading(false));
};

export const downloadPaymentInstruction = (id, fileName) => {
  blobRequest("payment_instructions", id, ".pdf").then((response) =>
    savePdfAttachment(response, fileName)
  );
};

export const downloadStatementOfAccountReport = (options, fileName) => {
  blobRequest("reports", "statement", ".pdf", options).then((response) =>
    savePdfAttachment(response, fileName)
  );
};
