import { useMemo, useCallback } from "react";
import { useLocalStorage } from "components/App/LocalStorageContext";

import { TENDER_SELECTED_KEY } from "../requests/utils";

export const clearSelectedRLTenders = () => {
  localStorage.removeItem(TENDER_SELECTED_KEY);
};
export const useSelectedRLTenders = () => {
  const [selectedTender, setSelectedTender] = useLocalStorage(TENDER_SELECTED_KEY, []);

  const addToTender = useCallback(
    ({ id, attributes }) => {
      setSelectedTender([...selectedTender, { id, attributes }]);
    },
    [selectedTender, setSelectedTender]
  );

  const removeFromTender = useCallback(
    (id) => {
      setSelectedTender(selectedTender.filter((item) => item.id !== id));
    },
    [selectedTender, setSelectedTender]
  );

  const clearSelected = useCallback(() => {
    setSelectedTender([]);
  }, [setSelectedTender]);

  return { selectedTender, addToTender, removeFromTender, clearSelected };
};

export const useSellwisorLink = (id) => {
  const currentEnv = useMemo(() => {
    if (window.location.href.includes("qa")) return "qa";
    if (window.location.href.includes("localhost")) return "development";
    return "production";
  }, []);

  const sellwisorLink = useMemo(() => {
    const base = {
      development: "http://localhost:3031",
      production: "https://sellwisor.com",
      qa: "https://qa.sellwisor.com",
    };
    return `${base[currentEnv]}/quotations/${id}`;
  }, [currentEnv, id]);

  return sellwisorLink;
};
