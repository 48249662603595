import find from "lodash/find";

/**
 * convert json-api response
 * add all *included* items to body
 * @param result - {Object} - api response
 * @returns {Object}
 */
export const piItemsToHeader = (result) => {
  if (!result.data.data.relationships) return result;

  const instructionInvoices = [];
  result.data.data.relationships.instruction_invoices.data.forEach((item) => {
    const incItem = find(result.data.included, item);
    instructionInvoices.push(incItem);
  });
  result.data.data.instruction_invoices = instructionInvoices;
  return result;
};

const groupPoItems = (data) =>
  data.reduce(
    (acc, item) => {
      const requisitionListId = item?.attributes?.requisition_list_id;

      if (requisitionListId) {
        acc.tenderItems.push(item);
      } else {
        acc.items.push(item);
      }

      return acc;
    },
    { items: [], tenderItems: [] }
  );

export const poItemsToHeader = (result) => {
  if (!result.data.data.relationships) return result;
  const { items, tenderItems } = groupPoItems(result?.data?.included);
  result.data.data.items = items;
  result.data.data.tenderItems = tenderItems;
  return result;
};
